import React from 'react';
import { AlternateButton } from 'components/AlternateButton';
import styles from './DesktopDropdown.module.scss';
import { DropdownProps } from 'components/List/Filters/Filter/Dropdown/types';

export const DesktopDropdown: React.FunctionComponent<DropdownProps> = ({
  filter,
  listContentId,
  selectedOptionIndex,
  selectOption,
}) => {
  /*
  Explicitly define list's ARIA role for Safari
  https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
  */
  /* eslint-disable jsx-a11y/no-redundant-roles */
  return (
    <ol
      className={styles.list}
      aria-controls={listContentId}
      aria-label={filter.title}
      role="list"
    >
      {filter.options.map((o, i) => {
        const isSelected = i === selectedOptionIndex;
        return (
          <li key={i} aria-current={isSelected} className={styles.item}>
            <AlternateButton
              isSelected={isSelected}
              size="large"
              text={o.title}
              onClick={() => selectOption(i)}
            />
          </li>
        );
      })}
    </ol>
  );
};
