import * as React from 'react';
import { ListLayoutProps } from '..';
import { useCards } from 'hooks/useCards';
import { useTranslation } from 'hooks/useTranslation';
import { sanitizeHtmlId } from 'utils/ui-helpers';
import { Container } from 'components/Container';
import { GridContainer, GridElement } from 'components/Grid';
import { PlaceholderCard } from 'components/PlaceholderCard';
import { Filters } from 'components/List/Filters';
import { useFilters } from 'components/List/Filters/useFilters';
import { ListNotifications } from 'components/List/ListNotifications/ListNotifications';
import CardPage from './CardPage';
import ScrollButton from './ScrollButton';
import styles from './VerticalList.module.scss';
import { UnorderedListMicrodata } from '../UnorderedListMicrodata';
import { useHideEmptyList } from '../hooks/useHideEmptyList';

const PAGE_SIZE = 10;

function isActiveCardOnPage(
  pageIndex: number,
  activeCardIndex: number
): boolean {
  return pageIndex === Math.floor(activeCardIndex / PAGE_SIZE);
}

function getActiveCardIndexOfPage(
  pageIndex: number,
  activeCardIndex: number | undefined
): number | undefined {
  if (
    activeCardIndex !== undefined &&
    isActiveCardOnPage(pageIndex, activeCardIndex)
  ) {
    return activeCardIndex % PAGE_SIZE;
  }
}

export const VerticalList: React.FunctionComponent<ListLayoutProps> = ({
  hideList,
  list,
  listKey,
  index,
  notificationComponent,
}) => {
  const { cursors = {}, filters = [] } = list;
  const listContentId = sanitizeHtmlId(listKey + 'content');

  const [visiblePages, setVisiblePages] = React.useState(1);
  const t = useTranslation();

  const {
    selectedFilterOptions,
    selectFilterOption,
    filterParameters,
    filterDefaultsSelected,
    filterStateKey,
  } = useFilters({
    listIndex: index,
    filters,
    resetListPaging: () => setVisiblePages(1),
  });
  const {
    errored: hasInitialCardFetchErrored,
    loading: isLoadingFirstCards,
    totalCount: totalCardCount,
    notifications,
  } = useCards({
    source: list.source,
    pageIndex: visiblePages - 1,
    pageSize: PAGE_SIZE,
    filterParameters,
  });

  useHideEmptyList({
    cardCount: totalCardCount,
    hasCardFetchErrored: hasInitialCardFetchErrored,
    hideList,
    isListLoadingFirstCards: isLoadingFirstCards,
    notifications,
  });

  const visibleCardCount = visiblePages * PAGE_SIZE;
  const areAllCardsVisible = visibleCardCount >= totalCardCount;
  const isShowMoreVisible = !areAllCardsVisible;

  const onShowMore = () => {
    if (!isLoadingFirstCards && !areAllCardsVisible) {
      setVisiblePages((current) => current + 1);
    }
  };

  const activeCardIndex = filterDefaultsSelected
    ? cursors.playlist?.offset
    : undefined;

  return (
    <div className="VerticalList">
      <Container>
        <ListNotifications
          notifications={notifications}
          listStyle={list.style}
          notificationComponent={notificationComponent}
        />
        <GridContainer>
          {filters.length > 0 ? (
            <GridElement mobile={12} tablet={3} desktopSmall={5}>
              <div className={styles.filters}>
                <Filters
                  filters={filters}
                  listContentId={listContentId}
                  selectFilterOption={selectFilterOption}
                  selectedFilterOptions={selectedFilterOptions}
                />
              </div>
            </GridElement>
          ) : null}
          <GridElement mobile={12} tablet={9} desktopSmall={17} desktopXL={14}>
            <div className={styles.wrapper}>
              <UnorderedListMicrodata
                className={styles.list}
                id={listContentId}
              >
                {Array.from({ length: visiblePages }, (_, pageIndex) => (
                  <CardPage
                    key={`${filterStateKey}-${pageIndex}`}
                    pageKey={`${listKey}-${filterStateKey}-${pageIndex}`}
                    listStyle={list.style}
                    pageIndex={pageIndex}
                    pageSize={PAGE_SIZE}
                    source={list.source}
                    filterParameters={filterParameters}
                    activeCardIndex={getActiveCardIndexOfPage(
                      pageIndex,
                      activeCardIndex
                    )}
                  />
                ))}
                {!areAllCardsVisible ? (
                  <CardPage
                    pageKey={`${listKey}-${filterStateKey}-${visiblePages}`}
                    listStyle={list.style}
                    pageIndex={visiblePages}
                    pageSize={PAGE_SIZE}
                    source={list.source}
                    hidden
                    filterParameters={filterParameters}
                  />
                ) : null}
                {isLoadingFirstCards &&
                  Array.from({ length: PAGE_SIZE }, (_, i) => (
                    <li className={styles.listItem} key={i}>
                      <PlaceholderCard listStyle={list.style} />
                    </li>
                  ))}
              </UnorderedListMicrodata>
              {isShowMoreVisible ? (
                <ScrollButton onClick={onShowMore} text={t('showMore')} />
              ) : null}
            </div>
          </GridElement>
        </GridContainer>
      </Container>
    </div>
  );
};
