import { ListStyle } from '@yleisradio/areena-types';
import classNames from 'classnames';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ListLayoutProps } from '..';
import { useCards } from 'hooks/useCards';
import { useTranslation } from 'hooks/useTranslation';
import { AlternateButton } from 'components/AlternateButton';
import { Container } from 'components/Container';
import { PlaceholderCard } from 'components/PlaceholderCard';
import { ListNotifications } from 'components/List/ListNotifications/ListNotifications';
import { CardPage } from './CardPage';
import styles from './GridList.module.scss';
import { UnorderedListMicrodata } from '../UnorderedListMicrodata';
import { useHideEmptyList } from '../hooks/useHideEmptyList';

const PAGE_SIZE = 16;

function getAspectRatioClass(
  listStyle: ListStyle | undefined
): string | undefined {
  if (!listStyle?.image || listStyle.image === '16:9') {
    return styles.listWithWideImages;
  } else {
    return styles.listWithNarrowImages;
  }
}

function getCardImageSizes(listStyle: ListStyle | undefined): string {
  if (!listStyle?.image || listStyle.image === '16:9') {
    return '(min-width: 1020px) 23vw, (min-width: 480px) 30vw, 45vw';
  } else {
    return '(min-width: 1400px) 15vw, (min-width: 800px) 18vw, (min-width: 640px) 23vw, (min-width: 480px) 26vw, 45vw';
  }
}

export const GridList: FunctionComponent<ListLayoutProps> = ({
  hideList,
  list,
  listKey,
  notificationComponent,
}) => {
  const [visiblePages, setVisiblePages] = useState(1);

  const {
    errored: hasInitialCardFetchErrored,
    loading: isLoadingFirstCards,
    notifications,
    totalCount: totalCardCount,
  } = useCards({
    source: list.source,
    pageIndex: visiblePages - 1,
    pageSize: PAGE_SIZE,
  });

  useHideEmptyList({
    cardCount: totalCardCount,
    hasCardFetchErrored: hasInitialCardFetchErrored,
    hideList,
    isListLoadingFirstCards: isLoadingFirstCards,
    notifications,
  });

  const t = useTranslation();
  const cardImageSizes = getCardImageSizes(list.style);

  const [isObserverSupported, setObserverSupport] = useState(true);
  const loadingTriggerElementRef = useRef<HTMLDivElement>(null);

  const visibleCardCount = visiblePages * PAGE_SIZE;
  const areAllCardsVisible = visibleCardCount >= totalCardCount;

  const showMore = useCallback(() => {
    if (!isLoadingFirstCards && !areAllCardsVisible) {
      setVisiblePages((current) => current + 1);
    }
  }, [areAllCardsVisible, isLoadingFirstCards]);

  useEffect(() => {
    if (isObserverSupported && !('IntersectionObserver' in window)) {
      setObserverSupport(false);
    }
  }, [isObserverSupported, setObserverSupport]);

  useEffect(() => {
    if (
      !isObserverSupported ||
      !loadingTriggerElementRef.current ||
      isLoadingFirstCards ||
      areAllCardsVisible
    )
      return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0]?.isIntersecting) {
        showMore();
      }
    });

    observer.observe(loadingTriggerElementRef.current);

    return () => {
      observer.disconnect();
    };
  }, [areAllCardsVisible, isLoadingFirstCards, isObserverSupported, showMore]);

  return (
    <Container>
      <ListNotifications
        notifications={notifications}
        listStyle={list.style}
        notificationComponent={notificationComponent}
      />
      <UnorderedListMicrodata
        className={classNames(styles.list, getAspectRatioClass(list.style))}
      >
        {Array.from({ length: visiblePages }, (_, pageIndex) => {
          return (
            <CardPage
              cardImageSizes={cardImageSizes}
              key={pageIndex}
              listStyle={list.style}
              pageIndex={pageIndex}
              pageKey={`${listKey}-${pageIndex}`}
              pageSize={PAGE_SIZE}
              source={list.source}
            />
          );
        })}

        {!areAllCardsVisible ? (
          <CardPage
            cardImageSizes={cardImageSizes}
            hidden
            listStyle={list.style}
            pageIndex={visiblePages}
            pageKey={`${listKey}-${visiblePages}`}
            pageSize={PAGE_SIZE}
            source={list.source}
          />
        ) : null}

        {isLoadingFirstCards &&
          Array.from({ length: PAGE_SIZE }, (_, i) => (
            <li key={i} aria-hidden>
              <PlaceholderCard listStyle={list.style} />
            </li>
          ))}
      </UnorderedListMicrodata>

      {!isLoadingFirstCards ? <div ref={loadingTriggerElementRef} /> : null}

      {!isObserverSupported && !areAllCardsVisible && (
        <div className={styles.loadMoreButton}>
          <AlternateButton
            text={t('showMore')}
            onClick={showMore}
            size={'large'}
            textAlignment={'center'}
          />
        </div>
      )}
    </Container>
  );
};
