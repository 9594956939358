import { ListStyle } from '@yleisradio/areena-types';
import classNames from 'classnames';
import { FunctionComponent, useCallback, useRef, useState } from 'react';
import { ListLayoutProps } from '..';
import { useCards } from 'hooks/useCards';
import { Container } from 'components/Container';
import { PlaceholderCard } from 'components/PlaceholderCard';
import { ListNotifications } from 'components/List/ListNotifications/ListNotifications';
import styles from './HorizontalList.module.scss';
import { ScrollButton } from './ScrollButton';
import { CardPage } from './CardPage';
import { UnorderedListMicrodata } from 'components/List/UnorderedListMicrodata';
import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';
import { useHideEmptyList } from '../hooks/useHideEmptyList';

const PAGE_SIZE = 10;

function getAspectRatioClass(
  listStyle: ListStyle | undefined
): string | undefined {
  switch (listStyle?.image) {
    case '1:1':
      return styles.aspectRatio_1_1;
    case '9:13':
      return styles.aspectRatio_9_13;
    case '16:9':
    default:
      return styles.aspectRatio_16_9;
  }
}

function getCardImageSizes(listStyle: ListStyle | undefined): string {
  if (!listStyle?.image || listStyle.image === '16:9') {
    return '(min-width: 1020px) 20vw, (min-width: 640px) 33vw, (min-width: 480px) 41vw, 60vw';
  } else {
    return '(min-width: 1400px) 15vw, (min-width: 800px) 18vw, (min-width: 640px) 23vw, (min-width: 480px) 26vw, 39vw';
  }
}

export const HorizontalList: FunctionComponent<ListLayoutProps> = ({
  hideList,
  list,
  listKey,
  notificationComponent,
}) => {
  const [visiblePages, setVisiblePages] = useState(1);

  const {
    errored: hasInitialCardFetchErrored,
    loading: isLoadingFirstCards,
    notifications,
    totalCount: totalCardCount,
  } = useCards({
    source: list.source,
    pageIndex: visiblePages - 1,
    pageSize: PAGE_SIZE,
  });

  useHideEmptyList({
    cardCount: totalCardCount,
    hasCardFetchErrored: hasInitialCardFetchErrored,
    hideList,
    isListLoadingFirstCards: isLoadingFirstCards,
    notifications,
  });

  const visibleCardCount = visiblePages * PAGE_SIZE;
  const areAllCardsVisible = visibleCardCount >= totalCardCount;

  const showMore = useCallback(() => {
    if (!isLoadingFirstCards && !areAllCardsVisible) {
      setVisiblePages((current) => current + 1);
    }
  }, [areAllCardsVisible, isLoadingFirstCards]);

  const cardImageSizes = getCardImageSizes(list.style);

  const scrollElementRef = useRef<HTMLDivElement>(null);
  const listElementRef = useRef<HTMLUListElement>(null);

  const [isScrollableLeft, setIsScrollableLeft] = useState(false);
  const [isScrollableRight, setIsScrollableRight] = useState(false);

  const setScrollableStates = () => {
    const scrollElement = scrollElementRef.current;

    if (scrollElement) {
      const { clientWidth, scrollLeft, scrollWidth } = scrollElement;

      if (scrollLeft > 0) {
        setIsScrollableLeft(true);
      } else if (scrollLeft <= 0) {
        setIsScrollableLeft(false);
      }

      const scrollEndMargin = 5;
      const scrollRightEnd = scrollWidth - scrollEndMargin;
      if (scrollRightEnd <= clientWidth) {
        setIsScrollableRight(false);
      } else {
        setIsScrollableRight(clientWidth + scrollLeft <= scrollRightEnd);
      }
    }
  };

  const onScroll = () => {
    const scrollElement = scrollElementRef.current;

    if (scrollElement) {
      const { clientWidth, scrollLeft, scrollWidth } = scrollElement;

      if (scrollLeft > scrollWidth - clientWidth * 1.5) {
        showMore();
      }

      setScrollableStates();
    }
  };

  useIsomorphicLayoutEffect(() => {
    setScrollableStates();
  });

  const scrollLeft = () => {
    const scrollElement = scrollElementRef.current;
    const listElement = listElementRef.current;

    if (scrollElement && listElement) {
      const containerWidth = listElement.clientWidth;

      scrollElement.scrollBy({ left: -containerWidth, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    const scrollElement = scrollElementRef.current;
    const listElement = listElementRef.current;

    if (scrollElement && listElement) {
      const containerWidth = listElement.clientWidth;

      scrollElement.scrollBy({ left: containerWidth, behavior: 'smooth' });
    }
  };

  return (
    <div className={classNames(styles.root, getAspectRatioClass(list.style))}>
      <div
        className={styles.scrollContainer}
        onScroll={onScroll}
        ref={scrollElementRef}
      >
        <Container>
          <ListNotifications
            listStyle={list.style}
            notifications={notifications}
            notificationComponent={notificationComponent}
          />
          <UnorderedListMicrodata className={styles.list} ref={listElementRef}>
            {Array.from({ length: visiblePages }, (_, pageIndex) => (
              <CardPage
                cardImageSizes={cardImageSizes}
                key={pageIndex}
                listItemClassName={styles.listItem}
                listStyle={list.style}
                pageIndex={pageIndex}
                pageKey={`${listKey}-${pageIndex}`}
                pageSize={PAGE_SIZE}
                source={list.source}
              />
            ))}

            {isLoadingFirstCards &&
              Array.from({ length: PAGE_SIZE }, (_, i) => (
                <li className={styles.listItem} key={i} aria-hidden>
                  <PlaceholderCard listStyle={list.style} />
                </li>
              ))}
          </UnorderedListMicrodata>
        </Container>
      </div>

      {totalCardCount > 0 && (
        <div className={styles.scrollButtonsOuterWrapper}>
          <Container>
            <div className={styles.scrollButtonsInnerWrapper}>
              <span
                className={classNames(
                  styles.scrollButton,
                  styles.scrollButtonLeft
                )}
              >
                <ScrollButton
                  direction="left"
                  hidden={!isScrollableLeft}
                  onClick={scrollLeft}
                />
              </span>
              <span
                className={classNames(
                  styles.scrollButton,
                  styles.scrollButtonRight
                )}
              >
                <ScrollButton
                  direction="right"
                  hidden={!isScrollableRight}
                  onClick={scrollRight}
                />
              </span>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};
